<template>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="text-center pt-5">
                <h1>{{$t('404 | Not Found')}}</h1>
            </div>
             <div v-if="displayHome" class="text-center">
                <router-link to="/"><button class="nxt-btn btn ntf-btn">Home</button></router-link>
            </div>
        </div>
    </div>
</div>
</template>
<script>

export default {
  name: "notfound",
  data() {
      return {
          displayHome:false
      }
  },
  
  mounted() {
    setTimeout(() => {
        if(this.$auth.setting && this.$auth.setting.length != 0){
            this.displayHome = true;
        }
    }, 2000);
    }
}
</script>
